import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { components } from 'ls-common-client';
import PageContainer from './PageContainer';

const { Container } = components;

const StyledContainer = styled(Container)`
  -webkit-overflow-scrolling: touch;
  top: 50%;
  left: 50%;
  position: absolute;
  max-width: 100%;
  transform: translate(-50%, -50%);
  padding: 20px;
  overflow: hidden;
`;

const PageContent = ({ children, ...props }) => {
  return (
    <StyledContainer
      display="flex"
      justifyContent="center"
      width="620px"
      margin="0 auto"
      {...props}
    >
      <PageContainer>{children}</PageContainer>
    </StyledContainer>
  );
};

PageContent.propTypes = {
  children: PropTypes.node,
};

PageContent.defaultProps = {
  children: undefined,
};

export default PageContent;
