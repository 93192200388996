import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { components } from 'ls-common-client';
import { localsearchBlue } from '../../images';
import PageContainer from './PageContainer';

const { Container, Image } = components;

const StyledContainer = styled(Container)`
  -webkit-overflow-scrolling: touch;
  padding: 0;
  margin: 0;
`;

const StyledPageContainer = styled(PageContainer)``;

const PageHeader = ({ children, title, ...props }) => {
  return (
    <StyledContainer
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      left="0"
      top="0"
      zIndex="2"
      width="100%"
      height="auto"
      {...props}
    >
      <StyledPageContainer
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="0 0 20px 0"
      >
        <Image width="138px" src={localsearchBlue} />
        <div
          style={{
            border: 'solid 1px #eaefff',
            width: '100%',
            margin: '20px 0 0',
          }}
        />
        {children}
      </StyledPageContainer>
    </StyledContainer>
  );
};

PageHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

PageHeader.defaultProps = {
  children: undefined,
  title: '',
};

export default PageHeader;
