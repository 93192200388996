/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { components, context } from 'ls-common-client';
import useReactRouter from 'use-react-router';
import queryString from 'query-string';

import { Page, PageContent, PageHeader } from '../../common';
import Listing from './Listing';
import User from './User';
import Empty from './Empty';

const { Container, Text, EmptyButton } = components;
const { MediaContext } = context;

const StyledContainer = styled(Container)`
  text-align: center;
  border-radius: 11px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  background: #ffffff;
  padding: 20px;
`;

const Home = () => {
  const { desktop } = useContext(MediaContext.Context);
  const [redirect, setRedirect] = useState();
  const { location } = useReactRouter();
  const { search } = location || {};
  const query = useMemo(() => queryString.parse(search || ''), [search]);

  const { email, type, listing } = query || {};

  useEffect(() => {
    if (!email && !type) setRedirect(true);
  }, [email, type]);

  return (
    <Page title="Unsubscribed">
      <PageContent>
        <StyledContainer paddingBottom={!desktop ? '20px' : '70px'}>
          <PageHeader />
          {redirect ? (
            <Empty />
          ) : listing ? (
            <Listing email={email} type={type} />
          ) : (
            <User email={email} type={type} />
          )}
        </StyledContainer>
        {redirect ? (
          ''
        ) : (
          <EmptyButton
            onClick={() => {
              window.open('', '_self').close();
            }}
            textAlign="center"
            color="normal"
            width="100%"
          >
            <Text
              style={{
                fontSize: '15px',
                textAlign: 'center',
                display: 'block',
                margin: '10px auto',
              }}
            >
              Close
            </Text>
          </EmptyButton>
        )}
      </PageContent>
    </Page>
  );
};

export default Home;
