import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { subscriptionService } from '../services';

const Context = React.createContext(null);

const Provider = ({ children }) => {
  const [isHuman, setIsHuman] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState({});
  const [unsubscribed, setUnsubscribed] = useState();

  const { email, type, feedback } = data || {};

  const getSubscription = async (emailData, typeData) => {
    const response = await subscriptionService.getSubscription(
      emailData,
      typeData
    );
    const { status } = response;
    if (status && status === 'unsubscribed') {
      return response;
    }
    return false;
  };

  const unsubscribe = async () => {
    const response = await subscriptionService.unsubscribe(
      email,
      type,
      feedback
    );
    const { status } = response;
    if (status === 'unsubscribed') {
      setUnsubscribed(response);
    }
    setLoading(false);
  };

  const setSubscription = async () => {
    const response = await getSubscription(email, type);
    if (response) {
      setUnsubscribed(response);
      setLoading(false);
    } else {
      await unsubscribe();
    }
  };

  useEffect(() => {
    if (isHuman && email && type) {
      setSubscription();
    }
  }, [isHuman, email, type]);

  return (
    <Context.Provider
      value={{
        isHuman,
        setIsHuman,
        setData,
        getSubscription,
        unsubscribed,
        loading,
        setError,
        error,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Context, Provider };
