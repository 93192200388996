const defaults = require('./defaults');

module.exports = {
  ...defaults,
  recaptcha: {
    baseUrl:
      'https://u67big8d6b.execute-api.ap-southeast-2.amazonaws.com/development',
  },
  subscriptions: {
    baseUrl:
      'https://w6eykzwt2b.execute-api.ap-southeast-2.amazonaws.com/development',
  },
};
