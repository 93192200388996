const local = require('./local');
const development = require('./development');
const test = require('./test');
const production = require('./production');

const config =
  {
    local,
    development,
    test,
    production,
  }[process.env.NODE_ENV] || local;

module.exports = config;
