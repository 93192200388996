const defaults = require('./defaults');

const { googleRecaptcha } = defaults;

module.exports = {
  ...defaults,
  debug: false,
  mode: 'production',
  sourceMap: 'source-map',
  hot: false,
  recaptcha: {
    baseUrl:
      'https://snt1hsxm54.execute-api.ap-southeast-2.amazonaws.com/production',
  },
  subscriptions: {
    baseUrl:
      'https://qw0djs1nmi.execute-api.ap-southeast-2.amazonaws.com/production',
  },
  googleRecaptcha: {
    ...googleRecaptcha,
    score: 0.5,
  },
  deploy: {
    domain: 'email.localsearch.com.au',
    zoneID: 'Z06416642YSUPVKEYEKWX',
    certificateArn:
      'arn:aws:acm:us-east-1:708891215413:certificate/b4628763-f22b-40df-9abb-e91add50cb10',
  },
};
