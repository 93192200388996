import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { components } from 'ls-common-client';

const { Container } = components;

const StyledContainer = styled(Container)`
  max-width: ${props => props.theme.custom.containerWidth.maxWidth};
`;

const PageContainer = ({ children, ...props }) => {
  return (
    <StyledContainer {...props} width="100%">
      {children}
    </StyledContainer>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node,
};

PageContainer.defaultProps = {
  children: undefined,
};

export default PageContainer;
