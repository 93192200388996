import config from '../../config';

const {
  subscriptions: { baseUrl },
} = config;

const unsubscribe = async (email, type, feedback) => {
  const response = await fetch(`${baseUrl}/v1/subscriptions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      type,
      message: feedback || null,
    }),
  });
  const body = await (response || {}).json();
  return body || {};
};

const getSubscription = async (email, type) => {
  const response = await fetch(
    `${baseUrl}/v1/subscriptions?email=${email}&type=${type}`,
    {
      method: 'GET',
    }
  );
  const body = await (response || {}).json();
  return body || {};
};

export { unsubscribe, getSubscription };
