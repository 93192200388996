import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { components } from 'ls-common-client';
import Home from './pages/Home';

const { Container } = components;

const Layout = () => {
  return (
    <Container
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      height="1px"
      minHeight="100%"
    >
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </Container>
  );
};

export default Layout;
