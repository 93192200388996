import React, {
  useContext,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { components, context, hooks } from 'ls-common-client';
import styled from 'styled-components';
import Unsubscribed from './Unsubscribed';
import { AppContext } from '../../../context';

const { Text, Button, TextArea } = components;
const { MediaContext } = context;
const { useForm } = hooks;

const StyledButton = styled(Button)`
  border: 1px solid ${props => props.theme.primary.normal};
  color: ${props => props.theme.primary.normal};
  border-radius: 22px;
  font-size: 16px;
  background-color: #ffffff;
  max-width: 228px;
  height: 44px;
  margin: 100px auto 50px;
`;

const Listing = ({ email, type }) => {
  const { desktop } = useContext(MediaContext.Context);
  const { setData, getSubscription } = useContext(AppContext.Context);
  const [unsubscribe, setUnsubcribe] = useState();
  const [loading, setLoading] = useState(true);

  const checkSubscription = useCallback(async () => {
    const response = await getSubscription(email, type);
    const { status } = response || {};
    if (status === 'unsubscribed') {
      setData({ email, type });
      setUnsubcribe(true);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [email, type]);

  useEffect(() => {
    checkSubscription();
  }, []);

  const { setValue, values, onSubmit, reset } = useForm({
    defaults: {
      feedback: '',
    },
    onSubmit: async ({ feedback }) => {
      try {
        setData({ email, type, feedback });
        setUnsubcribe(true);
      } catch (err) {
        reset();
        throw err;
      }
    },
  });

  if (unsubscribe) return <Unsubscribed />;

  if (loading) return '';

  return (
    <Fragment>
      <Text
        style={{
          fontSize: !desktop ? '24px' : '50px',
          fontWeight: 'bold',
          color: '#4a4a4a',
          textAlign: 'center',
          display: 'block',
          margin: '0 auto 20px',
        }}
      >
        Have feedback?
      </Text>
      <Text
        style={{
          fontSize: !desktop ? '18px' : '20px',
          color: '#4a4a4a',
          textAlign: 'center',
          display: 'block',
          maxWidth: '390px',
          margin: !desktop ? '0 auto 20px' : '0 auto 50px',
        }}
      >
        We love when you share! Let us know why you unsubscribed or anything
        else here.
      </Text>
      <form onSubmit={onSubmit} noValidate>
        <TextArea
          value={values.feedback}
          onChange={setValue}
          name="feedback"
          placeholder="Write feedback..."
          style={{
            minHeight: '150px',
            maxWidth: '475px',
            margin: '0 auto',
          }}
        />
        <StyledButton type="submit">Unsubscribe</StyledButton>
      </form>
    </Fragment>
  );
};

Listing.propTypes = {
  email: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Listing;
