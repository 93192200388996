import React from 'react';
import ReactDom from 'react-dom';
import { hot } from 'react-hot-loader/root';
import App from './components/App';
import logger from './logger';
import config from './config';

logger.info(config);

const Root = () => <App />;

const initialized = true;
const render = async () => {
  if (await initialized) {
    ReactDom.render(<Root />, document.getElementById('root'));
  }
};

render();

export default hot(render);
