import React from 'react';
import { themes, components, context } from 'ls-common-client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import Layout from './Layout';
import { AppContext } from '../context';
import CustomTheme from '../theme';
import config from '../../config';

const {
  googleRecaptcha: { apiKey },
} = config;

const { default: theme } = themes;
const { mediaQueries } = theme;
const { GlobalStyle: CommonStyle } = components;
const { MediaContext } = context;

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 1px;
    min-height:100%;
    background: #eaefff;
  }
  button {
    outline: none;
    &:active {
      outline: none;
    }
    &:not(disabled) {
      cursor: pointer;
    }
  }
`;

const customTheme = {
  ...theme,
  ...CustomTheme,
};

const App = () => (
  <GoogleReCaptchaProvider reCaptchaKey={apiKey}>
    <MediaContext.Provider mediaQueries={mediaQueries}>
      <ThemeProvider theme={customTheme}>
        <React.Fragment>
          <CommonStyle />
          <GlobalStyle />
          <AppContext.Provider>
            <Layout />
          </AppContext.Provider>
        </React.Fragment>
      </ThemeProvider>
    </MediaContext.Provider>
  </GoogleReCaptchaProvider>
);

export default App;
