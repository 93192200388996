const backgroundImage = {
  blueLinear: 'linear-gradient(82deg, #6084e0, #2a56cf)',
};

const containerWidth = {
  maxWidth: '1110px',
};

const colors = {
  orange: '#ed8a39',
  lightBlue: '#e9efff',
  lightGreen: '#eaf5f4',
  lightRed: '#f6eeee',
  lightOrange: '#fdf7f2',
};

const shadow = {
  normal: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  table: '0 0 3px 0 rgba(0, 0, 0, 0.1)',
};

const custom = {
  backgroundImage,
  containerWidth,
  colors,
  shadow,
};

export { custom };
