import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../../context';
import Unsubscribed from './Unsubscribed';

const User = ({ email, type }) => {
  const { setData } = useContext(AppContext.Context);

  useEffect(() => {
    setData({ email, type });
  }, [email, type]);

  return <Unsubscribed />;
};

User.propTypes = {
  email: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default User;
