import config from '../../config';

const {
  recaptcha: { baseUrl },
} = config;

const verify = async token => {
  const response = await fetch(`${baseUrl}/v1/recaptcha?token=${token}`, {
    method: 'GET',
  });
  const body = await (response || {}).json();
  return body || {};
};

export { verify };
