import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import config from '../../config';

const Page = ({ title, children }) => {
  return (
    <div>
      <Helmet>
        <title>{`${config.title} | ${title || ''}`}</title>
        <meta name="robots" content="noimageindex, nofollow, nosnippet" />
      </Helmet>
      {children}
    </div>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

Page.defaultProps = {
  title: undefined,
  children: undefined,
};

export default Page;
