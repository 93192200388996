import React, { useContext, Fragment } from 'react';
import { components, context } from 'ls-common-client';

import { AppContext } from '../../../context';
import { GoogleRecaptcha } from '../../common';
import { subscriptionGraphic } from '../../../images';
import { loading2 } from '../../../animations';

const { Image, Text, Animation } = components;
const { MediaContext } = context;

const Home = () => {
  const { desktop } = useContext(MediaContext.Context);
  const { loading } = useContext(AppContext.Context);

  return (
    <Fragment>
      <GoogleRecaptcha />
      {loading ? (
        <Animation
          flex="0 0 100px"
          data={loading2}
          maxWidth="200px"
          margin="0 auto"
        />
      ) : (
        <Fragment>
          <Text
            style={{
              fontSize: !desktop ? '24px' : '50px',
              fontWeight: 'bold',
              color: '#4a4a4a',
              textAlign: 'center',
              display: 'block',
              margin: '0 auto 20px',
            }}
          >
            You&apos;re unsubscribed!
          </Text>
          <Text
            style={{
              fontSize: !desktop ? '18px' : '20px',
              color: '#4a4a4a',
              textAlign: 'center',
              display: 'block',
              maxWidth: '390px',
              margin: !desktop ? '0 auto 20px' : '0 auto 50px',
            }}
          >
            Remember, if you ever have any questions, our team are always here
            to help.
          </Text>
          <Image
            src={subscriptionGraphic}
            maxWidth={!desktop ? '70%' : '400px'}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Home;
