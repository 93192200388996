import React, { useContext, Fragment } from 'react';
import { components, context } from 'ls-common-client';

import { empty2 } from '../../../animations';

const { Text, Animation } = components;
const { MediaContext } = context;

const Home = () => {
  const { desktop } = useContext(MediaContext.Context);

  return (
    <Fragment>
      <Text
        style={{
          fontSize: !desktop ? '24px' : '50px',
          fontWeight: 'bold',
          color: '#4a4a4a',
          textAlign: 'center',
          display: 'block',
          margin: '0 auto 20px',
        }}
      >
        Oops!
      </Text>
      <Animation
        flex="0 0 100px"
        data={empty2}
        maxWidth="300px"
        margin="0 auto"
      />
      <Text
        style={{
          fontSize: !desktop ? '18px' : '20px',
          color: '#4a4a4a',
          textAlign: 'center',
          display: 'block',
          maxWidth: '390px',
          margin: '30px auto',
        }}
      >
        There&apos;s nothing to see here.
      </Text>
    </Fragment>
  );
};

export default Home;
