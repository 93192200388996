import React, { useState, useContext, useCallback } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Context } from '../../context/AppContext';
import { recaptchaService } from '../../services';

const GoogleRecaptcha = () => {
  const { isHuman, setIsHuman } = useContext(Context);

  const [callHasBeenMade, setCallHasBeenMade] = useState();
  const [loading, setLoading] = useState();

  const verify = useCallback(
    async token => {
      if (!loading && !callHasBeenMade) {
        setLoading(true);
        const response = await recaptchaService.verify(token);
        const { success } = response || {};
        setCallHasBeenMade(true);
        setIsHuman(success);
      }
      setLoading(false);
    },
    [loading, callHasBeenMade]
  );

  return (
    <>
      {!isHuman && callHasBeenMade && (
        <p className="GoogleRecaptcha__error">
          Our system seems to think you&apos;re a bot.&nbsp;
        </p>
      )}
      <GoogleReCaptcha onVerify={verify} />
    </>
  );
};

export default GoogleRecaptcha;
