const { NODE_ENV: env = 'local', PORT: port = 8080 } = process.env;

module.exports = {
  env,
  port,
  debug: true,
  mode: 'development',
  sourceMap: true,
  hot: true,
  title: 'Localsearch',
  recaptcha: {
    baseUrl:
      'https://1ooodfpjkb.execute-api.ap-southeast-2.amazonaws.com/staging',
  },
  subscriptions: {
    baseUrl:
      'https://q1j1lvfoj9.execute-api.ap-southeast-2.amazonaws.com/staging',
  },
  googleRecaptcha: {
    baseUrl: 'https://www.google.com/recaptcha/api',
    apiKey: '6Lds6HYUAAAAAGJpFNullRbIYkohrW6PEZsD9ftx',
    secret: '6Lds6HYUAAAAAPY-uafAMsCWUrUmtTtYtMh4vC-t',
    score: 0,
  },
};
